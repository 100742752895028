import { Box, HStack, Text } from '@chakra-ui/react';
import React from 'react';

export const Card = ({
  title,
  subtitle,
  align,
  children,
  width,
  height,
  flex,
  subtitleSize,
  descriptionSize,
  titleSize,
  line,
  lineDesc,
}) => {
  return (
    <Box
      bgColor="transparentGray"
      color="black"
      px="2"
      py="6"
      h={height || 'full'}
      w={width || '270px'}
      flex={flex}
    >
      <HStack borderBottom="1px" display="flex" justifyContent="space-between">
        <Text
          fontSize={titleSize || '3xl'}
          fontWeight="400"
          lineHeight={line || '8'}
          pb="3"
          flex={1}
          textAlign={align || 'left'}
        >
          {title}
        </Text>
        {subtitle && (
          <Text
            fontSize={subtitleSize || 'md'}
            fontWeight="400"
            textAlign="left"
            lineHeight="8"
            pb="3"
          >
            {subtitle}
          </Text>
        )}
      </HStack>
      <Text
        fontSize={descriptionSize || '1xl'}
        fontWeight="300"
        lineHeight={lineDesc || '6'}
        mt="5"
        textAlign={align || 'left'}
      >
        {children}
      </Text>
    </Box>
  );
};
