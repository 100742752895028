import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Link as ChakraLink,
  Image,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ScrollLink, animateScroll } from 'react-scroll';
import menuBlack from '../../assets/menuBlack.png';

export const MenuScroll = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [showHeader, setShowHeader] = useState(false);

  const scrollToSection = to => {
    animateScroll.scrollTo(to, {
      duration: 500,
      smooth: true,
    });
  };

  useEffect(() => {
    let timeoutId = null;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos === 0) {
        setShowHeader(false);
      } else if (currentScrollPos > prevScrollPos) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }

      setPrevScrollPos(currentScrollPos);

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setShowHeader(true);
      }, 1000);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, [prevScrollPos]);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const drawerStyles = {
    height: '50vh',
    background: 'rgba(221, 221, 221, 0.90)',
  };

  return showHeader ? (
    <Box position="fixed" top={6} right={5}>
      <Box
        display="flex"
        bgColor="rgba(221, 221, 221, 0.90)"
        w="35px"
        h="40px"
        alignItems="center"
        justifyContent="center"
      >
        <button onClick={handleToggle}>
          <Image src={menuBlack} />
        </button>
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={handleToggle}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent style={drawerStyles}>
          <DrawerCloseButton size="lg" />
          <DrawerHeader></DrawerHeader>
          <DrawerBody>
            <VStack mt={4} spacing={4} align="center">
              <ChakraLink
                onClick={() => scrollToSection('sobre')}
                as={ScrollLink}
                to="sobre"
                smooth={true}
                duration={500}
              >
                sobre
              </ChakraLink>
              <ChakraLink
                onClick={() => {
                  scrollToSection('explore');
                  handleClose();
                }}
                as={ScrollLink}
                to="explore"
                smooth={true}
                duration={500}
              >
                explore
              </ChakraLink>
              <ChakraLink
                onClick={() => {
                  scrollToSection('exploit');
                  handleClose();
                }}
                as={ScrollLink}
                to="exploit"
                smooth={true}
                duration={500}
              >
                exploit
              </ChakraLink>
              <ChakraLink
                onClick={() => {
                  scrollToSection('ecossistema');
                  handleClose();
                }}
                as={ScrollLink}
                to="ecossistema"
                smooth={true}
                duration={500}
              >
                ecossistema
              </ChakraLink>
              <ChakraLink
                onClick={() => {
                  scrollToSection('atuacao');
                  handleClose();
                }}
                as={ScrollLink}
                to="atuacao"
                smooth={true}
                duration={500}
              >
                atuação
              </ChakraLink>
              <ChakraLink
                onClick={() => {
                  scrollToSection('network');
                  handleClose();
                }}
                as={ScrollLink}
                to="network"
                smooth={true}
                duration={500}
              >
                network
              </ChakraLink>
              <ChakraLink
                onClick={() => {
                  scrollToSection('contato');
                  handleClose();
                }}
                as={ScrollLink}
                to="contato"
                smooth={true}
                duration={500}
              >
                contato
              </ChakraLink>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  ) : null;
};
