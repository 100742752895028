import React from 'react';
import { Box, Text } from '@chakra-ui/react';

export const Tag = ({ title, description, flex }) => {
  return (
    <Box
      border="1px"
      flex="1"
      h={['22rem', '10rem', '12rem', '13rem', '13rem', '14rem']}
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent={!!description ? 'flex-start' : 'center'}
      px={4}
      py={7}
    >
      <Text fontSize="2xl" fontWeight="400" lineHeight="8">
        {title}
      </Text>
      {description && (
        <Text
          fontSize={['3vw', '3vw', '1.6vw', '1.4vw']}
          fontWeight="200"
          lineHeight="shorter"
          pt="5"
        >
          {description}
        </Text>
      )}
    </Box>
  );
};
