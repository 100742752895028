import {
  Box,
  HStack,
  Image,
  Spacer,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import { Title } from '../../common/Title';
import { CustomText } from '../../common/CustomText';
import logo from '../../assets/logo.svg';

import about from '../../assets/images/about.png';
import { Header } from '../Header';
import { Menu } from '../../common/Menu';

export const About = () => {
  const [isLargerThan1080] = useMediaQuery('(min-width: 1080px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  return (
    <VStack
      color="gray"
      bgImage={about}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      pt={['16', '16', '60', '60', '60']}
      px={['5', '5', '20', '20', '20', '300']}
      maxW="full"
    >
      {isLargerThan700 ? (
        <Header />
      ) : (
        <HStack>
          <VStack w="100%">
            <Image src={logo} w="10rem" />
            <Box
              w="100%"
              textAlign="left"
              position="absolute"
              top="102px"
              left="40px"
            >
              <Text fontWeight={100} fontSize="sm">
                ready to explore, prepare to exploit
              </Text>
            </Box>
          </VStack>
          <Box position="absolute" right="20px">
            <Menu />
          </Box>
        </HStack>
      )}
      <Spacer mt={['12', '12', '12', '24']} />
      <Title>SOBRE NÓS</Title>
      <Spacer mt={['2', '2', '4', '4', '4']} />
      <CustomText>
        Somos especialistas em preparar negócios e produtos early-stage para
        alcançar validações fundamentais e obter as condições e ferramentas para
        o crescimento
      </CustomText>
      <Spacer mt={[4, 4, '12', '12', '16']} />
      {isLargerThan1080 ? (
        <HStack>
          <Box flex={0.5} textAlign={'right'} mr={2}>
            <CustomText size="3xl" weight="200" line="shorter">
              Podemos atuar em duas frentes, uma implementando as melhores
              práticas para a identificação e validação de oportunidades e outra
              construindo os fundamentos para um crescimento sustentável
            </CustomText>
          </Box>
          <VStack flex={0.5} h={'full'} color="black" ml={2}>
            <Box
              display="flex"
              bgColor="transparentGray"
              w={'100%'}
              h={'full'}
              alignItems={'center'}
              justifyContent="center"
            >
              <CustomText
                size={['2xl', '2xl', '3xl', '4xl', '5xl', '5xl']}
                weight="200"
              >
                READY TO EXPLORE
              </CustomText>
            </Box>
            <Box
              display="flex"
              bgColor="transparentGray"
              w={'100%'}
              h={'full'}
              alignItems={'center'}
              justifyContent="center"
            >
              <CustomText
                size={['2xl', '2xl', '3xl', '4xl', '5xl', '5xl']}
                weight="200"
              >
                PREPARE TO EXPLOIT
              </CustomText>
            </Box>
          </VStack>
        </HStack>
      ) : (
        <VStack>
          <CustomText
            size={['md', '1xl', '2xl', '2xl', '2xl']}
            weight="200"
            line="shorter"
          >
            Podemos atuar em duas frentes, uma implementando as melhores
            práticas para a identificação e validação de oportunidades e outra
            construindo os fundamentos para um crescimento sustentável
          </CustomText>
          <Spacer mt={4} />
          {isLargerThan700 ? (
            <VStack>
              <Box
                display="flex"
                bgColor="transparentGray"
                w={'600px'}
                h={'108px'}
                alignItems={'center'}
                justifyContent="center"
                color="black"
              >
                <CustomText size="5xl" weight="200">
                  READY TO EXPLORE
                </CustomText>
              </Box>
              <Box
                display="flex"
                bgColor="transparentGray"
                w={'100%'}
                h={'108px'}
                alignItems={'center'}
                justifyContent="center"
                color="black"
              >
                <CustomText size="5xl" weight="200">
                  PREPARE TO EXPLOIT
                </CustomText>
              </Box>
            </VStack>
          ) : (
            <HStack>
              <Box
                display="flex"
                bgColor="transparentGray"
                h={'42px'}
                w="175px"
                alignItems={'center'}
                justifyContent="center"
                color="black"
              >
                <CustomText size="sm" weight="200">
                  READY TO EXPLORE
                </CustomText>
              </Box>
              <Box
                display="flex"
                bgColor="transparentGray"
                h={'42px'}
                w="175px"
                alignItems={'center'}
                justifyContent="center"
                color="black"
              >
                <CustomText size="sm" weight="200">
                  PREPARE TO EXPLOIT
                </CustomText>
              </Box>
            </HStack>
          )}
        </VStack>
      )}
      <Spacer mb={[32, 32, 64, 64, 64]} />
    </VStack>
  );
};
