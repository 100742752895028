import { HStack, Spacer, VStack, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { Title } from '../../common/Title';
import { CustomText } from '../../common/CustomText';
import { Card } from '../../common/Card';

import ecosystem from '../../assets/images/ecosystem.png';

export const Ecosystem = () => {
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  return isLargerThan700 ? (
    <VStack
      id="ecossistema"
      color="gray"
      bgImage={ecosystem}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      pt={['32', '32', '60', '60', '60']}
      mt="1px"
      px={['5', '5', '20', '20', '20', '300']}
      maxW="full"
    >
      <Title>ECOSSISTEMA</Title>

      <Spacer mt={['2', '2', '4', '4', '4']} />
      <HStack display="flex" alignItems="flex-start">
        <VStack
          textAlign={'right'}
          flex={0.5}
          display={'flex'}
          alignItems={'flex-end'}
        >
          <VStack>
            <Card
              width="auto"
              height="auto"
              title="Founders"
              subtitle="Da ideia ao negócio"
              titleSize="2xl"
              subtitleSize="xs"
            >
              Startups com apoio desde a ideação dos seus produtos têm
              desempenho superior e longevidade. Oferecemos suporte sênior e
              experiente para o planejamento estratégico, a captação de
              investimento, validação da proposta de valor e desenvolvimento de
              produto
            </Card>
            <Card
              width="auto"
              height="auto"
              title="Corporates"
              subtitle="Inovação e cultura de produto"
              titleSize="2xl"
              subtitleSize="xs"
            >
              Gestão de parcerias de inovação, investimento, incubação e de
              co-criação com startups que tenha como principais objetivos ganho
              de agilidade no desenvolvimento de produtos, melhora na
              experiência do cliente, mais eficiência operacional e geração de
              novas fontes de receita
            </Card>
            <Card
              width="auto"
              height="auto"
              title="Investors"
              subtitle="Acesso a negócios com alto potencial"
              titleSize="2xl"
              subtitleSize="xs"
            >
              Oferecemos a investidores de capital de risco acesso a
              oportunidades de negócios em que atuamos como co-investidores e
              influenciadores estratégicos e operacionais, com compromisso skin
              in the game e alvo na valorização dos ativos
            </Card>
            <Card
              width="auto"
              height="auto"
              title="Specialists"
              subtitle="Conexões e crescimento profissional"
              titleSize="2xl"
              subtitleSize="xs"
            >
              Especialistas em disciplinas relacionadas a inovação e
              desenvolvimento de produtos digitais podem fazer parte de uma rede
              que conecta empresas, startups e investidores e com isso ter
              acesso a oportunidades de trabalho dentro do ecossistema de
              venture capital
            </Card>
          </VStack>
        </VStack>
        <VStack
          textAlign={'left'}
          display={'flex'}
          alignItems={'flex-start'}
          flex={0.5}
        >
          <CustomText
            size={['4xl', '4xl', '4xl', '4xl', '5xl']}
            weight={200}
            margin={4}
          >
            Acreditamos em reduzir riscos e potencializar ganhos para o
            ecossistema que cria, opera e investe em negócios de alto potencial
            de crescimento
          </CustomText>
          <Spacer mx={4} my={6} borderBottom="1px" />
          <CustomText
            size={['1xl', '1xl', '2xl', '3xl', '3xl']}
            weight="200"
            line="shorter"
            margin={4}
          >
            Atuamos em interface com os atores do ecossistema de venture capital
            que opera e financia de forma criteriosa produtos e negócios com
            hipóteses bem fundamentadas e validadas, escalabilidade e menor
            exposição a risco
          </CustomText>
          <Spacer mb={8} />
          <CustomText
            size={['sm', 'sm', 'md', 'md', 'md']}
            line="taller"
            margin={4}
          >
            Open Innovation | Venture Capital | Venture Studio | Venture Builder
            | Corporate Venture Capital | Corporate Venture Builder | Seed
            Investment | Angel Investidor | Open Talent
          </CustomText>
        </VStack>
      </HStack>

      <Spacer pb="60" />
    </VStack>
  ) : (
    <VStack
      id="ecossistema"
      color="gray"
      bgImage={ecosystem}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      pt={['32', '32', '60', '60', '60']}
      mt="1px"
      px={['5', '5', '20', '20', '20']}
      maxW="full"
    >
      <Title>ECOSSISTEMA</Title>

      <Spacer mt="4" />
      <CustomText size="2xl">
        Acreditamos em reduzir riscos e potencializar ganhos para o ecossistema
        que cria, opera e investe em negócios de alto potencial de crescimento
      </CustomText>
      <Spacer my={2} />
      <CustomText size="md" weight="200" line="shorter">
        Atuamos em interface com os atores do ecossistema de venture capital que
        opera e financia de forma criteriosa produtos e negócios com hipóteses
        bem fundamentadas e validadas, escalabilidade e menor exposição a risco
      </CustomText>
      <VStack>
        <Spacer mt={4} />
        <HStack>
          <Card
            height="full"
            title="Founders"
            titleSize="md"
            descriptionSize="xs"
            width="180px"
            align="center"
            lineDesc="4"
          >
            Startups com apoio desde a ideação dos seus produtos têm desempenho
            superior e longevidade. Oferecemos suporte sênior e experiente para
            o planejamento estratégico, a captação de investimento, validação da
            proposta de valor e desenvolvimento de produto
          </Card>
          <Card
            titleSize="md"
            descriptionSize="xs"
            width="180px"
            align="center"
            height="full"
            title="Corporates"
            lineDesc="4"
          >
            Gestão de parcerias de inovação, investimento, incubação e de
            co-criação com startups que tenha como principais objetivos ganho de
            agilidade no desenvolvimento de produtos, melhora na experiência do
            cliente, mais eficiência operacional e geração de novas fontes de
            receita
          </Card>
        </HStack>
        <HStack>
          <Card
            height="full"
            title="Investors"
            titleSize="md"
            descriptionSize="xs"
            width="180px"
            align="center"
            lineDesc="4"
          >
            Oferecemos a investidores de capital de risco acesso a oportunidades
            de negócios em que atuamos como co-investidores e influenciadores
            estratégicos e operacionais, com compromisso skin in the game e alvo
            na valorização dos ativos
          </Card>
          <Card
            titleSize="md"
            descriptionSize="xs"
            width="180px"
            align="center"
            height="full"
            title="Specialists"
            lineDesc="4"
          >
            Especialistas em disciplinas relacionadas a inovação e
            desenvolvimento de produtos digitais podem fazer parte de uma rede
            que conecta empresas, startups e investidores e com isso ter acesso
            a oportunidades de trabalho dentro do ecossistema de venture capital
          </Card>
        </HStack>
      </VStack>

      <Spacer mb={1} />
      <CustomText
        size={['sm', 'sm', 'md', 'md', 'md']}
        line="taller"
        margin={4}
      >
        Open Innovation | Venture Capital | Venture Studio | Venture Builder |
        Corporate Venture Capital | Corporate Venture Builder | Seed Investment
        | Angel Investidor | Open Talent
      </CustomText>
      <Spacer mb={[32, 32, 64, 64, 64]} />
    </VStack>
  );
};
