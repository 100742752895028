import { Box, HStack, Spacer, VStack, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { Title } from '../../common/Title';
import { CustomText } from '../../common/CustomText';
import { Card } from '../../common/Card';

import exploit from '../../assets/images/exploit.png';

export const Exploit = () => {
  const [isLargerThan1080] = useMediaQuery('(min-width: 1080px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  return (
    <VStack
      id="exploit"
      color="gray"
      bgImage={exploit}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      pt={['32', '32', '60', '60', '60']}
      mt="1px"
      px={['5', '5', '20', '20', '20', '300']}
      maxW="full"
    >
      <Box mx="-24">
        <Title>PREPARE TO EXPLOIT</Title>
      </Box>
      <Spacer mt={['2', '2', '4', '4', '4']} />
      {isLargerThan1080 ? (
        <HStack display="flex" alignItems="flex-start">
          <VStack
            textAlign={'right'}
            flex={0.5}
            display={'flex'}
            alignItems={'flex-end'}
          >
            <CustomText margin={4}>
              <span>
                Identificação e
                <br />
                construção dos
                <br />
                fundamentos para o
                <br />
                crescimento
                <br />
                consistente e
                <br />
                sustentável
              </span>
            </CustomText>
            <HStack>
              <Card
                title={
                  <span>
                    Vision/
                    <br />
                    Concept Link
                  </span>
                }
                height={'25rem'}
              >
                A conexão de uma visão de produto que entrega propósito, inspira
                pessoas e cria compreensão comum do que se espera realizar com a
                concepção de produto orientada a solucionar problemas reais é
                pré requisito pra o crescimento
              </Card>
              <Card title="Solution/Market Validations Link" height={'25rem'}>
                O que acontece entre os momentos de validação do fit da solução
                com o problema e do fit do produto com o mercado, é a chave para
                determinarmos os fundamentos de uma estratégia de growth e
                vendas mais orgânica e eficiente
              </Card>
            </HStack>
          </VStack>
          <VStack
            textAlign={'left'}
            display={'flex'}
            alignItems={'flex-start'}
            flex={0.5}
          >
            <HStack>
              <Card
                title={
                  <span>
                    Product/
                    <br />
                    Growth Link
                  </span>
                }
                height={'25rem'}
              >
                A busca pelas validações fundamentais de produto deve ser
                concomitante e não negligenciar a construção, desde o início, de
                uma experiência de uso que transforme o próprio produto em fator
                chave de crescimento
              </Card>
              <Card
                title={
                  <span>
                    Strategy/
                    <br />
                    Execution Link
                  </span>
                }
                height={'25rem'}
              >
                Gerenciar os gaps que a complexidade de um ambiente exposto a
                constantes reavaliações e mudanças possa criar entre a visão de
                produto com as definições estratégicas pode promover a execução
                de uma trajetória de crescimento mais eficiente
              </Card>
            </HStack>
            <Spacer mb={1} />

            <Box mr={[0, 0, 0, 24, 32]}>
              <CustomText size="3xl" weight={200} margin={4}>
                Nunca foi tão determinante para um negócio ou produto early-
                stage possuir fundamentos sólidos resultado de uma estratégia
                sustentável de desenvolvimento de produto e crescimento para
                atrair talentos, clientes e investimento
              </CustomText>
            </Box>
          </VStack>
        </HStack>
      ) : isLargerThan700 ? (
        <VStack>
          <HStack display="flex" alignItems="center" w={'90%'}>
            <Box textAlign="right" flex={0.5} m={1}>
              <CustomText size="3xl">
                Identificação e construção dos fundamentos para o crescimento
                consistente e sustentável
              </CustomText>
            </Box>
            <Box textAlign="left" flex={0.5} m={1}>
              <CustomText size="1xl" weight={200}>
                Nunca foi tão determinante para um negócio ou produto early-
                stage possuir fundamentos sólidos resultado de uma estratégia
                sustentável de desenvolvimento de produto e crescimento para
                atrair talentos, clientes e investimento
              </CustomText>
            </Box>
          </HStack>
          <Spacer mt={4} />
          <HStack>
            <Card
              title={
                <span>
                  Product
                  <br />
                  Growth Link
                </span>
              }
              height={'md'}
              subtitleSize="1rem"
            >
              A busca pelas validações fundamentais de produto deve ser
              concomitante e não negligenciar a construção, desde o início, de
              uma experiência de uso que transforme o próprio produto em fator
              chave de crescimento
            </Card>
            <Card
              title={
                <span>
                  Strategy/
                  <br />
                  Execution Link
                </span>
              }
              height={'md'}
            >
              Gerenciar os gaps que a complexidade de um ambiente exposto a
              constantes reavaliações e mudanças possa criar entre a visão de
              produto com as definições estratégicas pode promover a execução de
              uma trajetória de crescimento mais eficiente
            </Card>
          </HStack>
          <HStack>
            <Card
              title={
                <span>
                  Vision/
                  <br />
                  Concept Link
                </span>
              }
              height={'md'}
            >
              A conexão de uma visão de produto que entrega propósito, inspira
              pessoas e cria compreensão comum do que se espera realizar com a
              concepção de produto orientada a solucionar problemas reais é pré
              requisito pra o crescimento
            </Card>
            <Card title="Solution/Market Validations Link" height={'md'}>
              O que acontece entre os momentos de validação do fit da solução
              com o problema e do fit do produto com o mercado, é a chave para
              determinarmos os fundamentos de uma estratégia de growth e vendas
              mais orgânica e eficiente
            </Card>
          </HStack>
        </VStack>
      ) : (
        <VStack w="100%">
          <CustomText size="2xl">
            Identificação e construção dos fundamentos para o crescimento
            consistente e sustentável
          </CustomText>
          <Spacer mt={4} />
          <CustomText size="md" weight={200}>
            Nunca foi tão determinante para um negócio ou produto early- stage
            possuir fundamentos sólidos resultado de uma estratégia sustentável
            de desenvolvimento de produto e crescimento para atrair talentos,
            clientes e investimento
          </CustomText>
          <Spacer mt={8} />
          <HStack>
            <Card
              title={'Product Growth Link'}
              height={'full'}
              titleSize="xs"
              descriptionSize="xs"
              width="180px"
              align="center"
              line="shorter"
              lineDesc={4}
            >
              A busca pelas validações fundamentais de produto deve ser
              concomitante e não negligenciar a construção, desde o início, de
              uma experiência de uso que transforme o próprio produto em fator
              chave de crescimento
            </Card>
            <Card
              title={'Strategy/ Execution Link'}
              height={'full'}
              titleSize="xs"
              descriptionSize="xs"
              width="180px"
              align="center"
              line="shorter"
              lineDesc={4}
            >
              Gerenciar os gaps que a complexidade de um ambiente exposto a
              constantes reavaliações e mudanças possa criar entre a visão de
              produto com as definições estratégicas pode promover a execução de
              uma trajetória de crescimento mais eficiente
            </Card>
          </HStack>
          <HStack>
            <Card
              title={'Vision/ Concept Link'}
              height={'full'}
              titleSize="xs"
              descriptionSize="xs"
              width="180px"
              align="center"
              line="shorter"
              lineDesc={4}
            >
              A conexão de uma visão de produto que entrega propósito, inspira
              pessoas e cria compreensão comum do que se espera realizar com a
              concepção de produto orientada a solucionar problemas reais é pré
              requisito pra o crescimento
            </Card>
            <Card
              title="Solution/Market Validations Link"
              height={'full'}
              titleSize="xs"
              descriptionSize="xs"
              width="180px"
              align="center"
              line="shorter"
              lineDesc={4}
            >
              O que acontece entre os momentos de validação do fit da solução
              com o problema e do fit do produto com o mercado, é a chave para
              determinarmos os fundamentos de uma estratégia de growth e vendas
              mais orgânica e eficiente
            </Card>
          </HStack>
        </VStack>
      )}
      <CustomText size={['sm', 'sm', 'md', 'md', 'md']} line="taller">
        Future Back Process | Design Fiction | Strategic Foresight | Product
        Vision | Product Discovery | Customer Development | Customer Validation
        | Product Market Fit | Continuous Discovery & Delivery | Roadmap Outcome
        Driven | Product Led Growth | Product Channel Fit
      </CustomText>
      <Spacer mb={[32, 32, 64, 64, 64]} />
    </VStack>
  );
};
