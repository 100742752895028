import React from 'react';
import { Text } from '@chakra-ui/react';

export const CustomText = ({ children, size, weight, line, margin }) => {
  return (
    <Text
      fontSize={size || ['2xl', '3xl', '3xl', '5xl', '5xl']}
      fontWeight={weight || '300'}
      lineHeight={line || 'normal'}
      mx={margin}
    >
      {children}
    </Text>
  );
};
