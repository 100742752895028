import { Box, HStack, Spacer, VStack, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { Title } from '../../common/Title';
import { CustomText } from '../../common/CustomText';
import { Emoji } from '../../common/Emoji';

import network from '../../assets/images/network.png';
import angel from '../../assets/emojis/Angel.png';
import bigSmile from '../../assets/emojis/Big-Smile.png';
import hypster from '../../assets/emojis/Hypster.png';
import inLove from '../../assets/emojis/In-Love.png';
import moneyEye from '../../assets/emojis/Money-Eye.png';
import ninja from '../../assets/emojis/Ninja.png';
import nird from '../../assets/emojis/Nird.png';
import substract from '../../assets/emojis/Subtract.png';
import sunglass from '../../assets/emojis/Sunglass.png';

export const Network = () => {
  const [isLargerThan1080] = useMediaQuery('(min-width: 1080px)');

  return (
    <VStack
      id="network"
      color="gray"
      bgImage={network}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      pt={['32', '32', '60', '60', '60']}
      mt="1px"
      px={['5', '5', '20', '20', '20', '300']}
      maxW="full"
    >
      <Title>NETWORK</Title>

      <Spacer mt={['2', '2', '4', '4', '4']} />
      <CustomText>
        Gestão de uma rede de talentos acionada sob demanda para diferentes
        momentos e necessidades do negócio
      </CustomText>
      <Spacer mt={['4', '4', '8', '8', '8']} />
      <CustomText size={['md', '1xl', '2xl', '3xl', '3xl']} weight={200}>
        Acesso sob demanda a especialistas qualificados pode ser determinante
        para um negócio ou produto early-stage que enfrenta desafios complexos e
        imprevistos, decisivos para alcançar suas validação e se preparar para o
        crescimento.
      </CustomText>

      <Spacer mt={'16'} />
      {isLargerThan1080 ? (
        <Box width="100%" bgColor="transparentGray" color="black">
          <Spacer mt={20} />
          <HStack
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Emoji emoji={inLove} label="Branding & Marketing" />
            <Emoji emoji={moneyEye} label="Growth & Sales" />
            <Emoji emoji={ninja} label="Product Management" />
            <Emoji emoji={nird} label="Tech & Development" />
            <Emoji emoji={hypster} label="Design & Research" />
          </HStack>
          <Spacer mt={24} />
          <HStack
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Emoji
              emoji={substract}
              label={
                <span>
                  Data <br />
                  Science
                </span>
              }
            />
            <Emoji emoji={bigSmile} label="People & Culture" />
            <Emoji emoji={angel} label="Legal & Finance" />
            <Emoji emoji={sunglass} label="Venture Capital" />
          </HStack>
          <Spacer mb={20} />
        </Box>
      ) : (
        <Box width="100%" bgColor="transparentGray" color="black">
          <Spacer mt={20} />
          <HStack
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Emoji emoji={inLove} label="Branding & Marketing" />
            <Emoji emoji={moneyEye} label="Growth & Sales" />
            <Emoji emoji={ninja} label="Product Management" />
          </HStack>
          <Spacer mt={24} />
          <HStack
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Emoji emoji={nird} label="Tech & Development" />
            <Emoji emoji={hypster} label="Design & Research" />
            <Emoji
              emoji={substract}
              label={
                <span>
                  Data <br />
                  Science
                </span>
              }
            />
          </HStack>
          <Spacer mt={24} />
          <HStack
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Emoji emoji={bigSmile} label="People & Culture" />
            <Emoji emoji={angel} label="Legal & Finance" />
            <Emoji emoji={sunglass} label="Venture Capital" />
          </HStack>
          <Spacer mb={20} />
        </Box>
      )}
      <Spacer mb={[32, 32, 64, 64, 64]} />
    </VStack>
  );
};
