import { Box, HStack, Spacer, VStack, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { Title } from '../../common/Title';
import { CustomText } from '../../common/CustomText';
import { Card } from '../../common/Card';

import explore from '../../assets/images/explore.png';

export const Explore = () => {
  const [isLargerThan1080] = useMediaQuery('(min-width: 1080px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  return (
    <VStack
      id="explore"
      color="gray"
      bgImage={explore}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      pt={['32', '32', '60', '60', '60']}
      mt="1px"
      px={['5', '5', '20', '20', '20', '300']}
      maxW="full"
    >
      <Box mx="-20">
        <Title>READY TO EXPLORE</Title>
      </Box>
      <Spacer mt={['2', '2', '4', '4', '4']} />
      <CustomText>
        Criar uma cultura propositiva e antecipatória e com isso estar pronto
        para as necessidades da descoberta e validação de hipóteses de geração
        de valor
      </CustomText>
      <Spacer mt={['4', '4', '8', '8', '8']} />
      <CustomText
        size={['md', '1xl', '2xl', '3xl', '3xl']}
        margin={['0', '0', '0', '0', '20']}
        weight={200}
      >
        O desafio nesse momento é conseguir orquestrar a imprevisibilidade de
        objetivos, a avaliação incerta de resultados com a busca incessante de
        aprendizado e fazendo isso com agilidade, lidando com a escassez e
        evitando distrações
      </CustomText>
      <Spacer mt={['8', '8', '16', '16', '16']} />
      {isLargerThan1080 ? (
        <HStack>
          <Card title="Lidar com a incerteza" height={'21rem'}>
            O objetivo é preparar o negócio para lidar com imprecisas evidências
            de sucesso e imprevisibilidade dos seus objetivos dentro de uma
            operação com alta capacidade de movimento e adaptação
          </Card>
          <Card
            title={
              <span>
                Aprendizado
                <br />
                como resultado
              </span>
            }
            height={'21rem'}
          >
            Um negócio early-stage precisa se concentrar nas descobertas
            fundamentais que viabilizarão seu propósito e para isso construímos
            uma operação que entenda aprendizado como resultado de negócio
          </Card>
          <Card title="Atuação ágil e enxuta" height={'21rem'}>
            Estruturamos a operação para uma atuação ágil e enxuta com alvo em
            um maior domínio da relação risco/recompensa e esforço/grau de
            certeza dos movimentos que o negócio passa a realizar
          </Card>
          <Card
            title={
              <span>
                Mais foco,
                <br />
                menos distração
              </span>
            }
            height={'21rem'}
          >
            O êxito de uma early stage vem da sua capacidade de agir com clareza
            e direção alinhadas com a estratégia. Configuramos métodos e
            critérios para evitar que distrações possam potencializar os riscos
          </Card>
        </HStack>
      ) : isLargerThan700 ? (
        <VStack>
          <HStack>
            <Card title="Lidar com a incerteza">
              O objetivo é preparar o negócio para lidar com imprecisas
              evidências de sucesso e imprevisibilidade dos seus objetivos
              dentro de uma operação com alta capacidade de movimento e
              adaptação
            </Card>
            <Card
              title={
                <span>
                  Aprendizado
                  <br />
                  como resultado
                </span>
              }
            >
              Um negócio early-stage precisa se concentrar nas descobertas
              fundamentais que viabilizarão seu propósito e para isso
              construímos uma operação que entenda aprendizado como resultado de
              negócio
            </Card>
          </HStack>
          <HStack>
            <Card title="Atuação ágil e enxuta">
              Estruturamos a operação para uma atuação ágil e enxuta com alvo em
              um maior domínio da relação risco/recompensa e esforço/grau de
              certeza dos movimentos que o negócio passa a realizar
            </Card>
            <Card
              title={
                <span>
                  Mais foco,
                  <br />
                  menos distração
                </span>
              }
            >
              O êxito de uma early stage vem da sua capacidade de agir com
              clareza e direção alinhadas com a estratégia. Configuramos métodos
              e critérios para evitar que distrações possam potencializar os
              riscos
            </Card>
          </HStack>
        </VStack>
      ) : (
        <VStack>
          <HStack>
            <Card
              title="Lidar com a incerteza"
              titleSize="xs"
              descriptionSize="xs"
              width="180px"
              align="center"
              lineDesc="smaller"
            >
              O objetivo é preparar o negócio para lidar com imprecisas
              evidências de sucesso e imprevisibilidade dos seus objetivos
              dentro de uma operação com alta capacidade de movimento e
              adaptação
            </Card>
            <Card
              title={'Aprendizado como resultado'}
              titleSize="xs"
              descriptionSize="xs"
              width="180px"
              align="center"
              lineDesc="smaller"
            >
              Um negócio early-stage precisa se concentrar nas descobertas
              fundamentais que viabilizarão seu propósito e para isso
              construímos uma operação que entenda aprendizado como resultado de
              negócio
            </Card>
          </HStack>
          <HStack>
            <Card
              title="Atuação ágil e enxuta"
              titleSize="xs"
              descriptionSize="xs"
              width="180px"
              align="center"
              lineDesc="smaller"
            >
              Estruturamos a operação para uma atuação ágil e enxuta com alvo em
              um maior domínio da relação risco/recompensa e esforço/grau de
              certeza dos movimentos que o negócio passa a realizar
            </Card>
            <Card
              title={'Mais foco, menos distração'}
              titleSize="xs"
              descriptionSize="xs"
              width="180px"
              align="center"
              lineDesc="smaller"
            >
              O êxito de uma early stage vem da sua capacidade de agir com
              clareza e direção alinhadas com a estratégia. Configuramos métodos
              e critérios para evitar que distrações possam potencializar os
              riscos
            </Card>
          </HStack>
        </VStack>
      )}
      <CustomText size={['sm', 'sm', 'md', 'md', 'md']} line="taller">
        Product Culture | Product Sense | Cone of Uncertainty | Blue Ocean
        Strategy | Aspirations and Key Insights | Decision Stack | Lean Startup
        | Agile Culture
      </CustomText>
      <Spacer mb={[32, 32, 64, 64, 64]} />
    </VStack>
  );
};
