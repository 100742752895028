import { Box, Spacer, VStack } from '@chakra-ui/react';
import React from 'react';
import { Title } from '../../common/Title';
import { CustomText } from '../../common/CustomText';

import follow from '../../assets/images/follow.png';

export const Follow = () => {
  const linkedInProfile = 'https://www.linkedin.com/company/rabbity/';
  const linkedInDeeplink = 'linkedin://company/rabbity';

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const linkedInLink = isMobile ? linkedInDeeplink : linkedInProfile;

  return (
    <VStack
      id="contato"
      color="gray"
      bgImage={follow}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      pt={['32', '32', '60', '60', '60']}
      mt="1px"
      px={['5', '5', '20', '20', '20', '300']}
      maxW="full"
    >
      <Box mx="-24">
        <Title>FOLLOW THE RABBIT!</Title>
      </Box>
      <Spacer mt={['2', '2', '4', '4', '4']} />
      <CustomText>
        Se você acredita que a nossa proposta possa ser útil para você e sua
        empresa, entre em contato conosco por{' '}
        <a
          href="mailto:sergio@rabbity.co"
          style={{
            position: 'relative',
            display: 'inline-block',
            textDecoration: 'none',
            borderBottom: '1px solid',
            paddingBottom: '0.5px',
          }}
        >
          e-mail
        </a>{' '}
        ou{' '}
        <a
          href={linkedInLink}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            position: 'relative',
            display: 'inline-block',
            textDecoration: 'none',
            borderBottom: '1px solid',
            paddingBottom: '0.5px',
          }}
        >
          LinkedIn{' '}
        </a>{' '}
        para conversarmos sobre isso
      </CustomText>
      <Spacer mb={[32, 32, 64, 64, 64]} />
    </VStack>
  );
};
