import React from 'react';
import { Box, Image, Spacer } from '@chakra-ui/react';
import { CustomText } from '../CustomText';

export const Emoji = ({ emoji, label }) => {
  return (
    <Box
      width="150px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mx={[2, 2, 8, 8, 8]}
    >
      <Image src={emoji} w={['40px', '60px', '60px', '90px']} />
      <Spacer mt={4} />
      <CustomText size={['md', 'md', '2xl', '2xl', '3xl']} weight={300}>
        {label}
      </CustomText>
    </Box>
  );
};
