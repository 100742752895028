import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import y from '../../assets/y.svg';

export const Footer = () => {
  return (
    <Box
      w={'100%'}
      height={['16', '20', '24', '24', '24']}
      color="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgColor="rgba(0, 194, 255, 1)"
    >
      <Image src={y} h={['40px', '40px', '64px', '64px', '64px']} />
    </Box>
  );
};
