import React from 'react';
import { Text } from '@chakra-ui/react';

export const Title = ({ children }) => {
  return (
    <Text
      fontSize={['4xl', '4xl', '6xl', '8xl', '9xl']}
      fontWeight="100"
      letterSpacing="-0.025em"
    >
      {children}
    </Text>
  );
};
