import { useState } from 'react';
import {
  Link as ChakraLink,
  VStack,
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Image,
} from '@chakra-ui/react';
import { Link as ScrollLink, animateScroll } from 'react-scroll';
import menu from '../../assets/menu.png';

export const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const scrollToSection = to => {
    animateScroll.scrollTo(to, {
      duration: 500,
      smooth: true,
    });
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const drawerStyles = {
    height: '50vh',
    background: 'rgba(221, 221, 221, 0.90)',
  };

  return (
    <>
      <Box>
        <button onClick={handleToggle}>
          <Image src={menu} />
        </button>
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={handleToggle}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent style={drawerStyles}>
          <DrawerCloseButton size="lg" />
          <DrawerHeader></DrawerHeader>
          <DrawerBody>
            <VStack mt={4} spacing={4} align="center">
              <ChakraLink
                onClick={() => scrollToSection('sobre')}
                as={ScrollLink}
                to="sobre"
                smooth={true}
                duration={500}
              >
                sobre
              </ChakraLink>
              <ChakraLink
                onClick={() => {
                  scrollToSection('explore');
                  handleClose();
                }}
                as={ScrollLink}
                to="explore"
                smooth={true}
                duration={500}
              >
                explore
              </ChakraLink>
              <ChakraLink
                onClick={() => {
                  scrollToSection('exploit');
                  handleClose();
                }}
                as={ScrollLink}
                to="exploit"
                smooth={true}
                duration={500}
              >
                exploit
              </ChakraLink>
              <ChakraLink
                onClick={() => {
                  scrollToSection('ecossistema');
                  handleClose();
                }}
                as={ScrollLink}
                to="ecossistema"
                smooth={true}
                duration={500}
              >
                ecossistema
              </ChakraLink>
              <ChakraLink
                onClick={() => {
                  scrollToSection('atuacao');
                  handleClose();
                }}
                as={ScrollLink}
                to="atuacao"
                smooth={true}
                duration={500}
              >
                atuação
              </ChakraLink>
              <ChakraLink
                onClick={() => {
                  scrollToSection('network');
                  handleClose();
                }}
                as={ScrollLink}
                to="network"
                smooth={true}
                duration={500}
              >
                network
              </ChakraLink>
              <ChakraLink
                onClick={() => {
                  scrollToSection('contato');
                  handleClose();
                }}
                as={ScrollLink}
                to="contato"
                smooth={true}
                duration={500}
              >
                contato
              </ChakraLink>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
