import { Box, HStack, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
import logo from '../../assets/logo.svg';
import { Link as ScrollLink, animateScroll } from 'react-scroll';

export const Header = () => {
  const scrollToSection = to => {
    animateScroll.scrollTo(to, {
      duration: 500,
      smooth: true,
    });
  };
  return (
    <Box w="100vw" color="white" position="absolute" p={8} top={6}>
      <HStack justifyContent={'space-between'}>
        <HStack alignItems={'center'}>
          <Image src={logo} w={['20', '20', '20', '32', 'auto']} />
          <Text fontWeight={100} fontSize={['xs', 'xs', 'xs', 'xs', 'xl']}>
            ready to explore, prepare to exploit
          </Text>
        </HStack>
        <HStack>
          <Link
            onClick={() => scrollToSection('sobre')}
            as={ScrollLink}
            to="sobre"
            smooth={true}
            duration={500}
            fontSize={['xs', 'xs', 'sm', 'sm', 'xl']}
          >
            sobre
          </Link>
          <Link
            ml={['0.5rem', '0.5rem', '0.5rem', '2rem']}
            onClick={() => scrollToSection('explore')}
            as={ScrollLink}
            to="explore"
            smooth={true}
            duration={500}
            fontSize={['xs', 'xs', 'sm', 'sm', 'xl']}
          >
            explore
          </Link>
          <Link
            ml={['0.5rem', '0.5rem', '0.5rem', '2rem']}
            onClick={() => scrollToSection('exploit')}
            as={ScrollLink}
            to="exploit"
            smooth={true}
            duration={500}
            fontSize={['xs', 'xs', 'sm', 'sm', 'xl']}
          >
            exploit
          </Link>
          <Link
            ml={['0.5rem', '0.5rem', '0.5rem', '2rem']}
            onClick={() => scrollToSection('ecossistema')}
            as={ScrollLink}
            to="ecossistema"
            smooth={true}
            duration={500}
            fontSize={['xs', 'xs', 'sm', 'sm', 'xl']}
          >
            ecossistema
          </Link>
          <Link
            ml={['0.5rem', '0.5rem', '0.5rem', '2rem']}
            onClick={() => scrollToSection('atuacao')}
            as={ScrollLink}
            to="atuacao"
            smooth={true}
            duration={500}
            fontSize={['xs', 'xs', 'sm', 'sm', 'xl']}
          >
            atuação
          </Link>
          <Link
            ml={['0.5rem', '0.5rem', '0.5rem', '2rem']}
            onClick={() => scrollToSection('network')}
            as={ScrollLink}
            to="network"
            smooth={true}
            duration={500}
            fontSize={['xs', 'xs', 'sm', 'sm', 'xl']}
          >
            network
          </Link>
          <Link
            ml={['0.5rem', '0.5rem', '0.5rem', '2rem']}
            onClick={() => scrollToSection('contato')}
            as={ScrollLink}
            to="contato"
            smooth={true}
            duration={500}
            fontSize={['xs', 'xs', 'sm', 'sm', 'xl']}
          >
            contato
          </Link>
        </HStack>
      </HStack>
    </Box>
  );
};
