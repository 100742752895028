import {
  Box,
  HStack,
  Spacer,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import { Title } from '../../common/Title';
import { CustomText } from '../../common/CustomText';
import { Card } from '../../common/Card';

import acting from '../../assets/images/acting.png';
import { Tag } from '../../common/Tag';

export const Acting = () => {
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  return (
    <VStack
      id="atuacao"
      color="gray"
      bgImage={acting}
      backgroundSize="auto"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      pt={['32', '32', '60', '60', '60']}
      mt="1px"
      px={['5', '5', '20', '20', '20', '300']}
      maxW="full"
    >
      <Title>ATUAÇÃO</Title>
      <Spacer mt={['2', '2', '4', '4', '4']} />
      <CustomText>
        Nossa proposta soma uma atuação hands-on com compromisso skin in the
        game
      </CustomText>
      <Spacer mt={['4', '4', '8', '8', '8']} />
      <CustomText
        size={['md', '1xl', '2xl', '3xl', '3xl']}
        margin={['0', '0', '0', '0', '20']}
        weight={200}
      >
        Oferecemos uma combinação de modelos de trabalho e contratação
        customizáveis que ofereçam o suporte operacional, técnico e de capital
        adequado para os desafios e momento de cada negócio
      </CustomText>
      <Spacer mt={['8', '8', '16', '16', '16']} />
      {isLargerThan700 ? (
        <HStack display="flex" alignItems="flex-start">
          <VStack w={'48%'}>
            <Card
              title="Co-operating"
              width="auto"
              height={['sm', 'sm', 'sm', '260px', '240px']}
              align="right"
            >
              Acesso contínuo a talentos que participam do dia a dia do negócio
              e com isso promovem aprendizados, trocas de experiência e
              orientação na resolução de problemas complexos, visão estratégica,
              validações, priorização e orientação de equipes
            </Card>
            <Card
              title="Co-creating"
              width="auto"
              height={['sm', 'sm', 'sm', '260px', '240px']}
              align="right"
            >
              Acesso sob demanda a um time multidisciplinar formado para um
              desafio específico do negócio que oferece agilidade e entrega uma
              visão externa que rompe com vieses e favorece o processo criativo
              para solução de problemas complexos
            </Card>

            <HStack>
              <Tag title="Modelos de contratação" />
              <Tag
                title="Vesting"
                description="Concessão de opções de ações que se tornam adquiridas ao longo do
            tempo"
              />
            </HStack>
          </VStack>
          <VStack w={'50%'}>
            <Card
              title="Advisoring"
              width="auto"
              height={['sm', 'sm', 'sm', '260px', '240px']}
            >
              Acompanhamentos periódicos orientados a resultado que oferecem
              aconselhamento e orientação de profissionais experientes que
              ajudam o negócio a se desenvolver e minimiza riscos comuns das
              etapas de desenvolvimento de produto e crescimento
            </Card>
            <Card
              title="Co-investing"
              width="auto"
              height={['sm', 'sm', 'sm', '260px', '240px']}
            >
              Temos interesse em investir e compartilhar com founders e outros
              investidores, por meio de capital e mentoria, a jornada e os
              desafios de viabilizar negócios incríveis que resolvam problemas
              reais da sociedade
            </Card>
            <HStack>
              <Tag
                title="Fee"
                description="Fee mensal vinculado à disponibilidade e qualificação que a atuação
            demande"
              />
              <Tag
                title="Equity"
                description="Participação na empresa em troca de capital aportado como investidor"
              />
            </HStack>
          </VStack>
          <HStack display="flex"></HStack>
        </HStack>
      ) : (
        <VStack display="flex" justifyContent="center" alignItems="center">
          <HStack>
            <Card
              title="Co-operating"
              titleSize="md"
              descriptionSize="xs"
              width="180px"
              align="center"
              lineDesc={4}
            >
              Acesso contínuo a talentos que participam do dia a dia do negócio
              e com isso promovem aprendizados, trocas de experiência e
              orientação na resolução de problemas complexos, visão estratégica,
              validações, priorização e orientação de equipes
            </Card>
            <Card
              title="Co-creating"
              titleSize="md"
              descriptionSize="xs"
              width="180px"
              align="center"
              lineDesc={4}
            >
              Acesso sob demanda a um time multidisciplinar formado para um
              desafio específico do negócio que oferece agilidade e entrega uma
              visão externa que rompe com vieses e favorece o processo criativo
              para solução de problemas complexos
            </Card>
          </HStack>
          <HStack>
            <Card
              title="Advisoring"
              titleSize="md"
              descriptionSize="xs"
              width="180px"
              align="center"
              lineDesc="4"
            >
              Acompanhamentos periódicos orientados a resultado que oferecem
              aconselhamento e orientação de profissionais experientes que
              ajudam o negócio a se desenvolver e minimiza riscos comuns das
              etapas de desenvolvimento de produto e crescimento
            </Card>
            <Card
              title="Co-investing"
              titleSize="md"
              descriptionSize="xs"
              width="180px"
              align="center"
              lineDesc="4"
            >
              Temos interesse em investir e compartilhar com founders e outros
              investidores, por meio de capital e mentoria, a jornada e os
              desafios de viabilizar negócios incríveis que resolvam problemas
              reais da sociedade
            </Card>
          </HStack>
          <Box borderWidth="1px" width="100%" p={4}>
            <Text fontSize="md" fontWeight={400}>
              Modelos de contratação
            </Text>
            <Spacer mt={4} borderBottom="1px" />
            <HStack mt={4}>
              <Box>
                <Text fontSize="md">Vesting</Text>
                <Spacer mb={1} />
                <Text fontSize="xs">
                  Concessão de opções de ações que se tornam adquiridas ao longo
                  do tempo
                </Text>
              </Box>
              <Box>
                <Text fontSize="md">Fee</Text>
                <Spacer mb={1} />
                <Text fontSize="xs">
                  Fee mensal vinculado à disponibilidade e qualificação que a
                  atuação demande
                </Text>
              </Box>
              <Box>
                <Text fontSize="md">Equity</Text>
                <Spacer mb={1} />
                <Text fontSize="xs">
                  Fee mensal vinculado à disponibilidade e qualificação que a
                  atuação demande
                </Text>
              </Box>
            </HStack>
          </Box>
        </VStack>
      )}

      <CustomText size={['sm', 'sm', 'md', 'md', 'md']} line="taller">
        C Level as a Service | Open Talent | Venture Capital | Venture Studio |
        Venture Builder | Corporate Venture Capital | Corporate Venture Builder
        | Seed Investment | Angel Investidor
      </CustomText>
      <Spacer mb={[32, 32, 64, 64, 64]} />
    </VStack>
  );
};
