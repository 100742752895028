import React from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  Grid,
  extendTheme,
  useMediaQuery,
} from '@chakra-ui/react';
import '@fontsource/archivo';

import { About } from './components/About';
import { Explore } from './components/Explore';
import { Exploit } from './components/Exploit';
import { Ecosystem } from './components/Ecosystem';
import { Acting } from './components/Acting';
import { Network } from './components/Network';
import { Follow } from './components/Follow';
import { Footer } from './components/Footer';
import { HeaderScroll } from './components/HeaderScroll';
import { MenuScroll } from './common/MenuScroll';

const theme = extendTheme({
  fonts: {
    body: `"Archivo", sans-serif`,
  },
  colors: {
    white: '#FFFFFF',
    gray: '#DDDDDD',
    transparentGray: 'rgba(221, 221, 221, 0.75)',
    black: '#303030',
  },
});

function App() {
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl" bgColor="white">
        <Grid minH="100vh">
          <VStack spacing={0} width="100vw">
            {isLargerThan700 ? <HeaderScroll /> : <MenuScroll />}
            <About />
            <Explore />
            <Exploit />
            <Ecosystem />
            <Acting />
            <Network />
            <Follow />
            <Footer />
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
