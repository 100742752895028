import { Box, HStack, Image, Link } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import logoY from '../../assets/y.svg';
import { Link as ScrollLink, animateScroll } from 'react-scroll';

export const HeaderScroll = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [showHeader, setShowHeader] = useState(false);

  const scrollToSection = to => {
    animateScroll.scrollTo(to, {
      duration: 500,
      smooth: true,
    });
  };

  useEffect(() => {
    let timeoutId = null;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos === 0) {
        setShowHeader(false);
      } else if (currentScrollPos > prevScrollPos) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }

      setPrevScrollPos(currentScrollPos);

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setShowHeader(true);
      }, 1000);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, [prevScrollPos]);

  return showHeader ? (
    <Box
      w={'95%'}
      color="black"
      bgColor="gray"
      p={2}
      position="fixed"
      top={6}
      pl={4}
      pr={8}
    >
      <HStack justifyContent={'space-between'}>
        <HStack alignItems={'center'}>
          <Image
            src={logoY}
            height={['24px', '24px', '24px', '24px', '35px']}
          />
        </HStack>
        <HStack>
          <Link
            fontSize={['xs', 'xs', 'md', 'md', 'xl']}
            fontWeight={200}
            onClick={() => scrollToSection('sobre')}
            as={ScrollLink}
            to="sobre"
            smooth={true}
            duration={500}
          >
            sobre
          </Link>
          <Link
            ml={['0.5rem', '0.5rem', '0.5rem', '2rem']}
            fontSize={['xs', 'xs', 'md', 'md', 'xl']}
            fontWeight={200}
            onClick={() => scrollToSection('explore')}
            as={ScrollLink}
            to="explore"
            smooth={true}
            duration={500}
          >
            explore
          </Link>
          <Link
            ml={['0.5rem', '0.5rem', '0.5rem', '2rem']}
            fontSize={['xs', 'xs', 'md', 'md', 'xl']}
            fontWeight={200}
            onClick={() => scrollToSection('exploit')}
            as={ScrollLink}
            to="exploit"
            smooth={true}
            duration={500}
          >
            exploit
          </Link>
          <Link
            ml={['0.5rem', '0.5rem', '0.5rem', '2rem']}
            fontSize={['xs', 'xs', 'md', 'md', 'xl']}
            fontWeight={200}
            onClick={() => scrollToSection('ecossistema')}
            as={ScrollLink}
            to="ecossistema"
            smooth={true}
            duration={500}
          >
            ecossistema
          </Link>
          <Link
            ml={['0.5rem', '0.5rem', '0.5rem', '2rem']}
            fontSize={['xs', 'xs', 'md', 'md', 'xl']}
            fontWeight={200}
            onClick={() => scrollToSection('atuacao')}
            as={ScrollLink}
            to="atuacao"
            smooth={true}
            duration={500}
          >
            atuação
          </Link>
          <Link
            ml={['0.5rem', '0.5rem', '0.5rem', '2rem']}
            fontSize={['xs', 'xs', 'md', 'md', 'xl']}
            fontWeight={200}
            onClick={() => scrollToSection('network')}
            as={ScrollLink}
            to="network"
            smooth={true}
            duration={500}
          >
            network
          </Link>
          <Link
            ml={['0.5rem', '0.5rem', '0.5rem', '2rem']}
            fontSize={['xs', 'xs', 'md', 'md', 'xl']}
            fontWeight={200}
            onClick={() => scrollToSection('contato')}
            as={ScrollLink}
            to="contato"
            smooth={true}
            duration={500}
          >
            contato
          </Link>
        </HStack>
      </HStack>
    </Box>
  ) : null;
};
